body.lg-on {
    overflow: hidden;
}

.lg-backdrop {
    z-index: 10003;
}

.lg-backdrop, .lg-toolbar {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.lg-outer {
    z-index: 10004;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
    background: rgba(0,0,0,.45);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-top: 5px !important;
}

.lg-video-object {
    background: #111;
}
