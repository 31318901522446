.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: var(--color-primary-800);
    height: 0.125rem;
    position: fixed;
    right: 100%;
    width: 100%;
    z-index: 10005;
}

@media print {
    .pace {
        display: none;
    }
}
